<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
// const PlayFab = require('playfab-web-sdk/src/PlayFab/PlayFabClientApi');
// const PlayFabClientSDK = require('playfab-web-sdk/src/PlayFab/PlayFabClientApi');

export default {
  name: 'App',
  created() {
    console.log(PlayFab); // eslint-disable-line
    console.log(PlayFabClientSDK); // eslint-disable-line
    PlayFab.settings.titleId = this.$appConfig.playfabTitleId; // eslint-disable-line
  },
}
</script>

<style>
#app {
  font-family: AgencyFBBold, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"),
    local("MaterialIcons-Regular"),
    url(./fonts/MaterialIcons-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "AgencyFBBold";
  src: local("AgencyFBBold"),
   url(./fonts/AgencyFBBold.ttf) format("truetype");
}
@font-face {
  font-family: "AgencyFBRegular";
  src: local("AgencyFBRegular"),
   url(./fonts/AgencyFBRegular.ttf) format("truetype");
}
@font-face {
  font-family: "FuturaLigW08-Regular";
  src: local("FuturaLigW08-Regular"),
   url(./fonts/FuturaLigW08-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "FuturaMedW08-Regular";
  src: local("FuturaMedW08-Regular"),
   url(./fonts/FuturaMedW08-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "FuturaW08-Bold";
  src: local("FuturaW08-Bold"),
   url(./fonts/FuturaW08-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "FuturaW08-Regular";
  src: local("FFuturaW08-Regular"),
   url(./fonts/FuturaW08-Regular.ttf) format("truetype");
}
</style>
