import store from '@/store'

export default {
  store,

  // For convenience, this is defined as a Vue plugin.
  // Access via this.$vstore or $vstore.
  install (Vue) {
    Vue.prototype.$vstore = store
  }
}
