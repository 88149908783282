import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/playerscan/:rfid?',
    name: 'PlayerScan',
    component: () => import(/* webpackChunkName: "playerscan" */ '../views/PlayerScanView.vue'),
    props: true,
  },
  {
    path: '/playerprofile',
    name: 'PlayerProfile',
    component: () => import(/* webpackChunkName: "playerprofile" */ '../views/PlayerProfileView.vue'),
    props: true,
  },
  {
    path: '/playeredit',
    name: 'PlayerEdit',
    component: () => import(/* webpackChunkName: "playeredit" */ '../views/PlayerEditView.vue'),
    props: true,
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: () => import(/* webpackChunkName: "leaderboard" */ '../views/LeaderboardView.vue'),
    props: true,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    props: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
