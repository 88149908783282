export class Session {
  _id = null;
  installationId = null;
  forceStart = 0;
  status = 0;
  teams = [];
  createAt = null;
  updatedAt = null;

  // public constants
  static STATUS_SCANNED = 0;
  static STATUS_QUEUED = 1;
  static STATUS_PLAYING = 2;
  static STATUS_COMPLETED = 3;
  static STATUS_CANCELED = 4;
  static STATUS_ERROR = 999;
  
  constructor(installationId = null, forceStart = 0, status = 0, teams = []) {
    this.installationId = installationId;
    this.forceStart = forceStart;
    this.status = status;
    this.teams = teams;
  }

  static statusToString(statusValue) {
    switch(statusValue) {
      case Session.STATUS_SCANNED: return "scanned";
      case Session.STATUS_QUEUED: return "queued";
      case Session.STATUS_PLAYING: return "playing";
      case Session.STATUS_COMPLETED: return "completed";
      case Session.STATUS_CANCELED: return "canceled";
      case Session.STATUS_ERROR: return "error";
      default: return "unknown";
    }
  }
}

export class SessionTeam {
  number = 0;
  players = [];

  constructor(number = 0, players = []) {
    this.number = number;
    this.players = players;
  }
}

export class SessionPlayer {
  id = null;
  name = null;
  score = 0;

  constructor(id = null, name = null, score = 0) {
    this.id = id;
    this.name = name;
    this.score = score;
  }
}
