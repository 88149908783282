import { Installation } from '@/models/installation.model';
const util = require('util');

export const InstallationsService = {
  async all() {
    const res = await this.playfabGetTitleData("Installations");
    return {data: res};
  },
  async get(id) {
    console.log(id);
    throw new Error('Not Implemented');
  },
  async add(data) {
    console.log(data);
    throw new Error('Not Implemented');
  },
  async update(id, data) {
    console.log(id);
    console.log(data);
    throw new Error('Not Implemented');
  },
  newInstallation() {
    return new Installation();
  },
  getStatusName (status) {
    switch (status) {
      case 0: return 'ONLINE'
      case 1: return 'ATTENTION'
      case 2: return 'OFFLINE'
      case 999: return 'OUT OF ORDER'
      default: 'UNKNOWN'
    }
  },

  //
  // PLAYFAB Helper Methods
  //

  // Method to get a specific PlayFab leaderboard around  a Player.
  async playfabGetTitleData(key) {
    const requestBody = {
      Keys: [key],
    };
    // util.promisify the PlayFabClientSDK method that uses callbacks
    // eslint-disable-next-line
    const getTitleData = util.promisify(PlayFabClientSDK.GetTitleData);
    return await getTitleData(requestBody)
      .then((res) => {
        console.error(res);
        throw(res);
      })
      .catch((err) => {
        const installations = err.data.Data.Installations;
        return JSON.parse(installations);
      });
  },
}
