import { config } from "@/config";
import axios from 'axios';
import { Session } from '@/models/session.model';

export const SessionsService = {
  async all() {
    return await axios.get(`${config.apiBaseUrl}/sessions`);
  },
  async get(id) {
    return await axios.get(`${config.apiBaseUrl}/sessions/${id}`);
  },
  async add(data) {
    return await axios.post(`${config.apiBaseUrl}/sessions`, data);
  },
  async update(id, data) {
    return await axios.put(`${config.apiBaseUrl}/sessions/${id}`, data);
  },
  async delete(id) {
    return await axios.delete(`${config.apiBaseUrl}/sessions/${id}`);
  },
  async getForInstallation(installationId) {
    return await axios.get(`${config.apiBaseUrl}/installations/${installationId}/sessions/open`);
  },
  async restart(id) {
    return await axios.get(`${config.apiBaseUrl}/sessions/restart/${id}`);
  },
  newSession() {
    return new Session();
  },
}
