import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import AuthStore from './modules/auth.store'
import UsersStore from './modules/users.store'
import PlayersStore from './modules/players.store'
import ActivationsStore from './modules/activations.store'
import InstallationsStore from './modules/installations.store'
import LeaderboardsStore from './modules/leaderboards.store'
import SessionsStore from './modules/sessions.store'

export default new Vuex.Store({
  state: {
    // User login access token
    accessToken: null,
    // Current signed in User
    user: null
  },
  mutations: {
    set (state, [variable, value]) {
      state[variable] = value
    },
  },
  actions: {
  },
  getters: {
  },
  modules: {
    auth: AuthStore,
    users: UsersStore,
    players: PlayersStore,
    activations: ActivationsStore,
    installations: InstallationsStore,
    leaderboards: LeaderboardsStore,
    sessions: SessionsStore,
  },
  strict: process.env.NODE_ENV !== 'production',
})
