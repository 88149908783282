import { Leaderboard } from '@/models/leaderboard.model';
const util = require('util');

export const LeaderboardsService = {
  async all() {
    const res = await this.playfabGetTitleData("Leaderboards");
    return {data: res};
  },
  async get(id) {
    const res = await this.playfabGetLeaderboard(id);
    return {data: res};
  },
  async add(data) {
    console.log(data);
    throw new Error('Not Implemented');
  },
  async update(id, data) {
    console.log(id);
    console.log(data);
    throw new Error('Not Implemented');
  },
  newLeaderboard() {
    return new Leaderboard();
  },

  //
  // PLAYFAB Helper Methods
  //

  // Method to get a specific PlayFab leaderboard around  a Player.
  async playfabGetTitleData(key) {
    const requestBody = {
      Keys: [key],
    };
    // util.promisify the PlayFabClientSDK method that uses callbacks
    // eslint-disable-next-line
    const getTitleData = util.promisify(PlayFabClientSDK.GetTitleData);
    return await getTitleData(requestBody)
      .then((res) => {
        console.error(res);
        throw(res);
      })
      .catch((err) => {
        const leaderboards = err.data.Data.Leaderboards;
        return JSON.parse(leaderboards);
      });
  },
  /**
   * Method to call PlayFabServer API GetLeaderboard.
   * @param {string} name - Unique identifier for the title-specific statistic 
   *  for the leaderboard.
   * @param {number} startPosition - First entry in the leaderboard to be retrieved.
   * @param {number} maxResultsCount - Maximum number of entries to retrieve.
   * @returns 
   */
   async playfabGetLeaderboard(
    name
  ) {
    const requestBody = {
      StatisticName: name,
      StartPosition: 0,
      MaxResultsCount: 100,
    };
 
    // promisify the PlayFabServer method that uses callbacks
    const getLeaderboard = util.promisify(PlayFabClientSDK.GetLeaderboard);
   
    return await getLeaderboard(requestBody)
    .then((res) => {
      throw(res);
    })
    .catch((err) => {
      return err.data.Leaderboard; 
    });
  },
}
