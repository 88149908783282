import { UsersService } from '@/services/users.service';

const NAMESPACE = 'users/';

// mutation consts
const SET_USERS = 'setUsers';
const SET_USER = 'setUser';
const NEW_USER = 'newUser';
const UPDATE_FIELD = 'updateField';
const ERROR = 'error';
const SUCCESS = 'success';
export const USERS_SET_USERS = NAMESPACE + SET_USERS;
export const USERS_SET_USER = NAMESPACE + SET_USER;
export const USERS_NEW_USER = NAMESPACE + NEW_USER;
export const USERS_UPDATE_FIELD = NAMESPACE + UPDATE_FIELD;
export const USERS_ERROR = NAMESPACE + ERROR;
export const USERS_SUCCESS = NAMESPACE + SUCCESS;

// action consts
const GET_ALL = 'getAll';
const GET_ONE = 'getOne';
const SAVE_USER = 'saveUser';
const DELETE_USER = 'deleteUser';
export const USERS_GET_ALL = NAMESPACE + GET_ALL;
export const USERS_GET_ONE = NAMESPACE + GET_ONE;
export const USERS_SAVE_USER = NAMESPACE + SAVE_USER;
export const USERS_DELETE_USER = NAMESPACE + DELETE_USER;

// getter consts
const INITIALS = 'initials';
export const USERS_INITIALS = NAMESPACE + INITIALS;


const state = {
  users: [],
  user: UsersService.newUser(),
  error: false,
  success: false,

}

const mutations = {
  set (state, [variable, value]) {
    state[variable] = value
  },
  [ERROR] (state, error) {
    state.error = error;
    state.success = false;
  },
  [SUCCESS] (state) {
    state.error = false;
    state.success = true;
  },
  [SET_USER] (state, user) {
    state.user = user;
  },
  [SET_USERS] (state, users) {
    state.users = users;
  },
  [NEW_USER] (state) {
    state.user = UsersService.newUser();
  },
  [UPDATE_FIELD] (state, {field, value}) {
    Object.assign(state.user, {
      [field]: value
    });
  },
}

const getters = {
  [INITIALS]: state => {
    let initials = '';
    if (state.user) {
      const firstName = state.user.firstName? state.user.firstName : '';
      const lastName = state.user.lastName? state.user.lastName : '';
      initials = (firstName.slice(0,1) + lastName.slice(0,1)).toUpperCase();
    }
    return initials;
  }
}

const actions = {
  async [GET_ALL] ({ commit }) {
    try {
      commit(SUCCESS);
      const res = await UsersService.all();
      commit(SET_USERS, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
      commit(SET_USERS, []);
    }
  },
  async [GET_ONE] ({ commit }, id) {
    try {
      commit(SUCCESS);
      const res = await UsersService.get(id);
      commit(SET_USER, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
      commit(NEW_USER);
    }
  },
  async [SAVE_USER] ({ state, commit }) {
    try {
      commit(SUCCESS);
      let res;
      if (state.user._id) {
        res = await UsersService.update(state.user._id, state.user);
      } else {
        res = await UsersService.add(state.user);
      }
      commit(SET_USER, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
  async [DELETE_USER] ({ state, commit }) {
    try {
      commit(SUCCESS);
      if (state.user._id) {
        await UsersService.delete(state.user._id);
      }
      commit(NEW_USER);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
