export class Player {
  _id = null;
  playerId = null;
  customId = null;
  displayName = null;
  firstName = null;
  lastName = null;
  emailAddress = null;
  password = null;
  avatarUrl = null;
  language = 'EN';
  isBanned = false;
  lastLogin = null;
  createAt = null;
  updatedAt = null;
}