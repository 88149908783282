export class User {
  _id = null;
  username = null;
  password = null;
  customId = null;
  firstName = null;
  lastName = null;
  emailAddress = null;
  language = 'EN';
  role = null;
  settings = null;
  lastLogin = null;
  createAt = null;
  updatedAt = null;
}
