// general.helper.ts
  
// Method to get the value of a nested property and checking for the existence
// of the property in the object.
export function getNestedProp(obj, ...args) {
  return args.reduce((obj, level) => obj && obj[level], obj)
}

// Method to remove the _id (objectId) field from a DTO object if it exists.
export function removeObjectIdProp(dto) {
  let result;
  if ('_id' in dto) {
    result = JSON.parse(JSON.stringify(dto));
    delete result._id;
  } else {
    result = dto;
  }
  return result;
}