import { LeaderboardsService } from '@/services/leaderboards.service';

const NAMESPACE = 'leaderboards/';

// mutation consts
const SET_LEADERBOARDS = 'setLeaderboards';
const SET_LEADERBOARD = 'setLeaderboard';
const NEW_LEADERBOARD = 'newLeaderboard';
const UPDATE_FIELD = 'updateField';
const ERROR = 'error';
const SUCCESS = 'success';
export const LEADERBOARDS_SET_LEADERBOARDS = NAMESPACE + SET_LEADERBOARDS;
export const LEADERBOARDS_SET_LEADERBOARD = NAMESPACE + SET_LEADERBOARD;
export const LEADERBOARDS_NEW_LEADERBOARD = NAMESPACE + NEW_LEADERBOARD;
export const LEADERBOARDS_UPDATE_FIELD = NAMESPACE + UPDATE_FIELD;
export const LEADERBOARDS_ERROR = NAMESPACE + ERROR;
export const LEADERBOARDS_SUCCESS = NAMESPACE + SUCCESS;

// action consts
const GET_ALL = 'getAll';
const GET_ONE = 'getOne';
const SAVE_LEADERBOARD = 'saveLeaderboard';
const DELETE_LEADERBOARD = 'deleteLeaderboard';
export const LEADERBOARDS_GET_ALL = NAMESPACE + GET_ALL;
export const LEADERBOARDS_GET_ONE = NAMESPACE + GET_ONE;
export const LEADERBOARDS_SAVE_LEADERBOARD = NAMESPACE + SAVE_LEADERBOARD;
export const LEADERBOARDS_DELETE_LEADERBOARD = NAMESPACE + DELETE_LEADERBOARD;

// getter consts
const DISPLAYNAME = 'displayName';
export const LEADERBOARDS_DISPLAYNAME = NAMESPACE + DISPLAYNAME;


const state = {
  leaderboards: [],
  leaderboard: LeaderboardsService.newLeaderboard(),
  error: false,
  success: false,
}

const mutations = {
  set (state, [variable, value]) {
    state[variable] = value
  },
  [ERROR] (state, error) {
    state.error = error;
    state.success = false;
  },
  [SUCCESS] (state) {
    state.error = false;
    state.success = true;
  },
  [SET_LEADERBOARD] (state, leaderboard) {
    state.leaderboard = leaderboard;
  },
  [SET_LEADERBOARDS] (state, leaderboards) {
    state.leaderboards = leaderboards;
  },
  [NEW_LEADERBOARD] (state) {
    state.leaderboard = LeaderboardsService.newLeaderboard();
  },
  [UPDATE_FIELD] (state, {field, value}) {
    Object.assign(state.leaderboard, {
      [field]: value
    });
  },
}

const getters = {
  [DISPLAYNAME]: (state) => (name) => {
    const leaderboard = state.leaderboards.find((lb) => lb.name === name);
    return leaderboard? leaderboard.displayName : '';
  }
}

const actions = {
  async [GET_ALL] ({ commit }) {
    try {
      commit(SUCCESS);
      const res = await LeaderboardsService.all();
      commit(SET_LEADERBOARDS, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
  async [GET_ONE] ({ commit }, id) {
    try {
      
      commit(SUCCESS);
      const leaderboard = state.leaderboards.find((lb) => lb._id === id);
      const pfLeaderboard = await LeaderboardsService.get(leaderboard.name);
      
      leaderboard.players=[];
      pfLeaderboard.data.forEach((player) => {
        const leaderboardPlayer = {
          id: player.PlayFabId,
          displayName: player.DisplayName,
          statValue: player.StatValue,
          position: player.Position
        }
        leaderboard.players.push(leaderboardPlayer);
      });
      
      commit(SET_LEADERBOARD, leaderboard);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
  async [SAVE_LEADERBOARD] ({ state, commit }) {
    try {
      commit(SUCCESS);
      let res; 
      if (state.leaderboard._id) {
        res = await LeaderboardsService.update(state.leaderboard._id, state.leaderboard);
      } else {
        res = await LeaderboardsService.add(state.leaderboard);
      }
      commit(SET_LEADERBOARD, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
  async [DELETE_LEADERBOARD] ({ state, commit }) {
    try {
      commit(SUCCESS);
      if (state.leaderboard._id) {
        await LeaderboardsService.delete(state.leaderboard._id);
      }
      commit(NEW_LEADERBOARD);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
