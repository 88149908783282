// ./src/config.js
const config = {
  apiBaseUrl: parse(process.env.VUE_APP_API_BASE_URL, 'https://localhost:3003'),
  appTitle: parse(process.env.VUE_APP_TITLE, 'Game Hub Portal'),
  appVersion: parse(process.env.VUE_APP_VERSION, '0.0.0'),
  playfabTitleId: parse(process.env.VUE_APP_PLAYFAB_TITLEID, 'AAAAA'),
  isRFIDReversed: parse(process.env.VUE_APP_IS_RFID_REVERSED, true),
  features: {
    //example: parse(process.env.VUE_APP_FEATURE_EXAMPLE, false),
  },
}

// Use parse() so that config file to reliably cast all config settings to the
// appropriate type.
function parse (value, fallback) {
  if (typeof value === 'undefined') {
    return fallback
  }
  switch (typeof fallback) {
    case 'boolean' :
      return !!JSON.parse(value)
    case 'number' :
      return JSON.parse(value)
    default :
      return value
  }
}

// Feature toggle to turn optional app features on/off by config.
function feature (name) {
  return config.features[name];
}

export {
  config
}

// For convenience, this is defined as a Vue plugin.
// Instead of accessing env vars, you access config settings via
// this.$appConfig or $appConfig, eg this.$appConfig.apiBaseUrl
export default {
  install (Vue) {
    Vue.appConfig = config
    Vue.feature = feature
    Vue.prototype.$appConfig = config
    Vue.prototype.$feature = feature
  }
}