import { ActivationsService } from '@/services/activations.service';

const NAMESPACE = 'activations/';

// mutation consts
const SET_ACTIVATIONS = 'setActivations';
const SET_ACTIVATION = 'setActivation';
const NEW_ACTIVATION = 'newActivation';
const UPDATE_FIELD = 'updateField';
const ERROR = 'error';
const SUCCESS = 'success';
export const ACTIVATIONS_SET_ACTIVATIONS = NAMESPACE + SET_ACTIVATIONS;
export const ACTIVATIONS_SET_ACTIVATION = NAMESPACE + SET_ACTIVATION;
export const ACTIVATIONS_NEW_ACTIVATION = NAMESPACE + NEW_ACTIVATION;
export const ACTIVATIONS_UPDATE_FIELD = NAMESPACE + UPDATE_FIELD;
export const ACTIVATIONS_ERROR = NAMESPACE + ERROR;
export const ACTIVATIONS_SUCCESS = NAMESPACE + SUCCESS;

// action consts
const GET_ALL = 'getAll';
const GET_ONE = 'getOne';
const SAVE_ACTIVATION = 'saveActivation';
const DELETE_ACTIVATION = 'deleteActivation';
export const ACTIVATIONS_GET_ALL = NAMESPACE + GET_ALL;
export const ACTIVATIONS_GET_ONE = NAMESPACE + GET_ONE;
export const ACTIVATIONS_SAVE_ACTIVATION = NAMESPACE + SAVE_ACTIVATION;
export const ACTIVATIONS_DELETE_ACTIVATION = NAMESPACE + DELETE_ACTIVATION;


const state = {
  activations: [],
  activation: ActivationsService.newActivation(),
  error: false,
  success: false,
}

const mutations = {
  set (state, [variable, value]) {
    state[variable] = value
  },
  [ERROR] (state, error) {
    state.error = error;
    state.success = false;
  },
  [SUCCESS] (state) {
    state.error = false;
    state.success = true;
  },
  [SET_ACTIVATION] (state, activation) {
    state.activation = activation;
  },
  [SET_ACTIVATIONS] (state, activations) {
    state.activations = activations;
  },
  [NEW_ACTIVATION] (state) {
    state.activation = ActivationsService.newActivation();
  },
  [UPDATE_FIELD] (state, {field, value}) {
    Object.assign(state.activation, {
      [field]: value
    });
  },
}

const getters = {
}

const actions = {
  async [GET_ALL] ({ commit }) {
    try {
      commit(SUCCESS);
      const res = await ActivationsService.all();
      commit(SET_ACTIVATIONS, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
      commit(SET_ACTIVATIONS, []);
    }
  },
  async [GET_ONE] ({ commit }, id) {
    try {
      commit(SUCCESS);
      const res = await ActivationsService.get(id);
      commit(SET_ACTIVATION, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
      commit(SET_ACTIVATION, null);
    }
  },
  async [SAVE_ACTIVATION] ({ state, commit }) {
    try {
      commit(SUCCESS);
      let res; 
      if (state.activation._id) {
        res = await ActivationsService.update(state.activation._id, state.activation);
      } else {
        res = await ActivationsService.add(state.activation);
      }
      commit(SET_ACTIVATION, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
  async [DELETE_ACTIVATION] ({ state, commit }) {
    try {
      commit(SUCCESS);
      if (state.activation._id) {
        await ActivationsService.delete(state.activation._id);
      }
      commit(NEW_ACTIVATION);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
