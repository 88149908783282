import { Activation } from '@/models/activation.model';
const util = require('util');

export const ActivationsService = {
  async all() {
    const res = await this.playfabGetTitleData("activations");
    return {data: res};
  },
  async get(id) {
    console.log(id);
    throw new Error('Not Implemented');
  },
  async add(data) {
    console.log(data);
    throw new Error('Not Implemented');
  },
  async update(id, data) {
    console.log(id);
    console.log(data);
    throw new Error('Not Implemented');
  },
  newActivation() {
    return new Activation();
  },

  //
  // PLAYFAB Helper Methods
  //

  // Method to get a specific PlayFab leaderboard around  a Player.
  async playfabGetTitleData(key) {
    const requestBody = {
      Keys: [key],
    };
    // util.promisify the PlayFabClientSDK method that uses callbacks
    // eslint-disable-next-line
    const getTitleData = util.promisify(PlayFabClientSDK.GetTitleData);
    return await getTitleData(requestBody)
      .then((res) => {
        console.error(res);
        throw(res);
      })
      .catch((err) => {
        const activations = err.data.Data.Activations;
        return JSON.parse(activations);
      });
  },

}
