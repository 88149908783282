import { Player } from "@/models/player.model";
import { getNestedProp } from '@/common/general.helper';
import { Profanity } from '@/common/profanity';
const util = require('util');

export const PlayersService = {
  async register(id) {
    console.log(id);
    throw new Error('Not Implemented');
  },
  async login(id) {
    const player = await this.playfabLoginWithCustomID(id);
    return {data: player};
  },
  async all() {
    throw new Error('Not Implemented');
  },
  async get(id) {
    // eslint-disable-next-line
    if (PlayFabClientSDK.IsClientLoggedIn()) {
      const player = await this.playfabGetPlayerCombinedInfo(id);
      return {data: player};
    }
  },
  async getByName(name) {
    console.log(name);
    throw new Error('Not Implemented');
  },
  async add(data) {
    console.log(data);
    throw new Error('Not Implemented');
  },
  async update(id, data) {
    console.log(id);
    console.log(data);
    throw new Error('Not Implemented');
  },
  async kioskUpdate(id, data) {
    // eslint-disable-next-line
    if (PlayFabClientSDK.IsClientLoggedIn()) {
      console.log(data);
      if (data.displayName) {
        return await this.playfabUpdateUserTitleDisplayName(id, data.displayName);
      }
    }
  },
  async delete(id) {
    console.log(id);
    throw new Error('Not Implemented');
  },
  async getDisplayName(digits=3) {
    console.log(digits);
    throw new Error('Not Implemented');
  },
  async getStatistics(id) {
    // eslint-disable-next-line
    if (PlayFabClientSDK.IsClientLoggedIn()) {
      const res = await this.playfabGetPlayerStatistics(id);
      const stats = res.Statistics.map((stat) => {
        return {name: stat.StatisticName, value: stat.Value};
      })
      return {data: {statistics: stats}};
    }
  },
  async getLeaderboard(id, leaderboardName) {
    // eslint-disable-next-line
    if (PlayFabClientSDK.IsClientLoggedIn()) {
      const res = await this.playfabGetLeaderboardAroundPlayer(id, leaderboardName);
      const lboards = res.Leaderboard.map((l) => {
        return {
          name: leaderboardName, 
          position: l.Position,
          value: l.StatValue,
        };
      })
      const lboard = lboards.length > 0? lboards[0] : 
        {name: leaderboardName, position: 0, value: 0};
      console.log(lboard);
      return {data: {leaderboard: lboard}};
    }
  },
  isProfane(value) {
    value = value.toLowerCase();
    return (Profanity.list().some(v => value.includes(v)));
  },
    newPlayer() {
    return new Player();
  },

  // 
  // PLAYFAB Helper Methods
  //

  // Method to login with the CustomId, and return fields:
  //   PlayerId, LastLogin, DisplayName, Contact Email, CustomId, 
  //   Banned Until (if available).
  async playfabLoginWithCustomID(id, createAccount = false) {
    const requestBody = {
    // eslint-disable-next-line
    TitleId: PlayFab.settings.titleId,
      CustomId: id,
      CreateAccount: createAccount,
      InfoRequestParameters: {
        GetUserAccountInfo: true,
        GetPlayerProfile: true,
        GetUserData: true,
        GetUserReadOnlyData: true,
        ProfileConstraints: {
          ShowContactEmailAddresses: true,
        },
      },
    };

    // eslint-disable-next-line
    const loginWithCustomID = util.promisify(PlayFabClientSDK.LoginWithCustomID);
    return await loginWithCustomID(requestBody)
      .then((res) => {
        console.error(res);
        throw new Error(res.error, res.errorMessage);
      })
      .catch((err) => {
        // stuff the returned data into an UpdatePlayerDto object
        return this.playfabGetPlayerFromInfoResultPayload(err);
      });
  },
  // Method to get the Player's Combined profile info for fields:
  //   PlayerId, LastLogin, DisplayName, Contact Email, CustomId, 
  //   Banned Until (if available).
  async playfabGetPlayerCombinedInfo(playFabId) {
    const requestBody = {
      PlayFabId: playFabId,
      InfoRequestParameters: {
        GetUserAccountInfo: true,
        GetPlayerProfile: true,
        GetUserData: true,
        GetUserReadOnlyData: true,
        ProfileConstraints: {
          ShowContactEmailAddresses: true,
        },
      },
    };
    // util.promisify the PlayFabClientSDK method that uses callbacks
    // eslint-disable-next-line
    const getPlayerCombinedInfo = util.promisify(PlayFabClientSDK.GetPlayerCombinedInfo);
    return await getPlayerCombinedInfo(requestBody)
      .then((res) => {
        // stuff the returned data into an UpdatePlayerDto object
        return this.playfabGetPlayerFromInfoResultPayload(res);
      })
      .catch((err) => {
        console.log(err.message);
        throw(err);
      });
  },
  // Method to stuff the returned player info data into an UpdatePlayerDto object.
  playfabGetPlayerFromInfoResultPayload(infoResult) {
    let player = new Player();
    const payload = getNestedProp(infoResult.data, 'InfoResultPayload');
    if (payload) {
      player.playerId = getNestedProp(payload, 'AccountInfo', 'PlayFabId');
      player._id = player.playerId;
      player.customId = getNestedProp(payload, 'AccountInfo', 'CustomIdInfo', 'CustomId');
      player.displayName = getNestedProp(payload, 'AccountInfo', 'TitleInfo', 'DisplayName');
      player.isBanned = getNestedProp(payload, 'AccountInfo', 'TitleInfo', 'isBanned');
      player.lastLogin = getNestedProp(payload, 'AccountInfo', 'TitleInfo', 'LastLogin');
      player.avatarUrl = getNestedProp(payload, 'AccountInfo', 'TitleInfo', 'AvatarUrl');
      player.firstName = getNestedProp(payload, 'UserData', 'FirstName', 'Value');
      player.lastName = getNestedProp(payload, 'UserData', 'LastName', 'Value');
      player.settings = getNestedProp(payload, 'UserData', 'Settings', 'Value');
      const contactEmails = getNestedProp(payload, 'PlayerProfile', 'ContactEmailAddresses');
      player.emailAddress = (contactEmails && contactEmails.length > 0)? contactEmails[0].EmailAddress : null;
      player.password = null;
      player.language = 'EN'; // TODO
    }
    return player;
  },
  // Method to update the User Data for FirstName, LastName.
  async playfabUpdateUserData(
    playFabId,
    firstName = null,
    lastName = null,
    settings = null,
  ) {
    const requestBody = {
      PlayFabId: playFabId,
      Data: {
        FirstName: firstName,
        LastName: lastName,
        Settings: settings,
      },
    };
    // util.promisify the PlayFabClientSDK method that uses callbacks
    const updateUserData = util.promisify(
      // eslint-disable-next-line
      PlayFabClientSDK.UpdateUserData,
    );
    return await updateUserData(requestBody);
  },
  // Method to update the User's Title DisplayName.
  async playfabUpdateUserTitleDisplayName(
    playFabId,
    displayName,
  ) {
    const requestBody = {
      PlayFabId: playFabId,
      DisplayName: displayName,

    };
    // util.promisify the PlayFabClientSDK method that uses callbacks
    const updateUserTitleDisplayName = util.promisify(
      // eslint-disable-next-line
      PlayFabClientSDK.UpdateUserTitleDisplayName,
    );
    return await updateUserTitleDisplayName(requestBody);
  },
  // Method to get the PlayFab statistics for a Player.
  async playfabGetPlayerStatistics(
    playFabId,
  ) {
    const requestBody = {
      PlayFabId: playFabId,
    };
    // util.promisify the PlayFabClientSDK method that uses callbacks
    // eslint-disable-next-line
    const getPlayerStatistics = util.promisify(PlayFabClientSDK.GetPlayerStatistics);
    return await getPlayerStatistics(requestBody)
      .then((res) => {
        console.error(res);
        throw(res);
      })
      .catch((err) => {
        console.log(err.data);
        return err.data;
      });
  },
  // Method to get a specific PlayFab leaderboard around  a Player.
  async playfabGetLeaderboardAroundPlayer(
    playFabId,
    leaderboardName,
  ) {
    const requestBody = {
      PlayFabId: playFabId,
      StatisticName: leaderboardName,
      MaxResultsCount: 1,
    };
    // util.promisify the PlayFabClientSDK method that uses callbacks
    // eslint-disable-next-line
    const getLeaderboardAroundPlayer = util.promisify(PlayFabClientSDK.GetLeaderboardAroundPlayer);
    return await getLeaderboardAroundPlayer(requestBody)
      .then((res) => {
        console.error(res);
        throw(res);
      })
      .catch((err) => {
        console.log(err.data);
        return err.data;
      });
  },
}
