import { InstallationsService } from '@/services/installations.service';

const NAMESPACE = 'installations/';

// mutation consts
const SET_INSTALLATIONS = 'setInstallations';
const SET_INSTALLATION = 'setInstallation';
const NEW_INSTALLATION = 'newInstallation';
const UPDATE_FIELD = 'updateField';
const ERROR = 'error';
const SUCCESS = 'success';
export const INSTALLATIONS_SET_INSTALLATIONS = NAMESPACE + SET_INSTALLATIONS;
export const INSTALLATIONS_SET_INSTALLATION = NAMESPACE + SET_INSTALLATION;
export const INSTALLATIONS_NEW_INSTALLATION = NAMESPACE + NEW_INSTALLATION;
export const INSTALLATIONS_UPDATE_FIELD = NAMESPACE + UPDATE_FIELD;
export const INSTALLATIONS_ERROR = NAMESPACE + ERROR;
export const INSTALLATIONS_SUCCESS = NAMESPACE + SUCCESS;

// action consts
const GET_ALL = 'getAll';
const GET_ONE = 'getOne';
const SAVE_INSTALLATION = 'saveInstallation';
const DELETE_INSTALLATION = 'deleteInstallation';
export const INSTALLATIONS_GET_ALL = NAMESPACE + GET_ALL;
export const INSTALLATIONS_GET_ONE = NAMESPACE + GET_ONE;
export const INSTALLATIONS_SAVE_INSTALLATION = NAMESPACE + SAVE_INSTALLATION;
export const INSTALLATIONS_DELETE_INSTALLATION = NAMESPACE + DELETE_INSTALLATION;


const state = {
  installations: [],
  installation: InstallationsService.newInstallation(),
  error: false,
  success: false,
}

const mutations = {
  set (state, [variable, value]) {
    state[variable] = value
  },
  [ERROR] (state, error) {
    state.error = error;
    state.success = false;
  },
  [SUCCESS] (state) {
    state.error = false;
    state.success = true;
  },
  [SET_INSTALLATION] (state, installation) {
    state.installation = installation;
  },
  [SET_INSTALLATIONS] (state, installations) {
    state.installations = installations;
  },
  [NEW_INSTALLATION] (state) {
    state.installation = InstallationsService.newInstallation();
  },
  [UPDATE_FIELD] (state, {field, value}) {
    Object.assign(state.installation, {
      [field]: value
    });
  },
}

const getters = {
}

const actions = {
  async [GET_ALL] ({ commit }) {
    try {
      commit(SUCCESS);
      const res = await InstallationsService.all();
      commit(SET_INSTALLATIONS, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
  async [GET_ONE] ({ commit }, id) {
    try {
      commit(SUCCESS);
      const res = await InstallationsService.get(id);
      commit(SET_INSTALLATION, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
  async [SAVE_INSTALLATION] ({ state, commit }) {
    try {
      commit(SUCCESS);
      let res; 
      if (state.installation._id) {
        res = await InstallationsService.update(state.installation._id, state.installation);
      } else {
        res = await InstallationsService.add(state.installation);
      }
      commit(SET_INSTALLATION, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
  async [DELETE_INSTALLATION] ({ state, commit }) {
    try {
      commit(SUCCESS);
      if (state.installation._id) {
        await InstallationsService.delete(state.installation._id);
      }
      commit(NEW_INSTALLATION);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
