import { config } from "@/config";
import axios from 'axios';

export const AuthService = {
  async login(username, password) {
    return await axios
      .post(`${config.apiBaseUrl}/auth/login`, {
        'username': username, 'password': password
      });
  },
  async loginRfid(rfid) {
    return await axios
      .post(`${config.apiBaseUrl}/auth/login`, {
        'username': rfid, 'password': 'rfid'
      });
  },
  async getProfile(accessToken) {
    return await axios
      .get(`${config.apiBaseUrl}/auth/profile`, {
        headers: {'Authorization': 'bearer ' + accessToken}
      });
  },
}
