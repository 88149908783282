export class Installation {
  _id = null;
  activationId = null;
  activationCode = null;
  displayName = null;
  code = null;
  baseUrl = null;
  status = 0;
  errorCode = 0;
  settings = null;
  lastStatusAt = null;
  createAt = null;
  updatedAt = null;

  static getStatusName (status) {
    switch (status) {
      case 0: return 'Offline'
      case 1: return 'Online'
      case 999: return 'Out of Order'
      default: 'Unknown'
    }
  }
}
