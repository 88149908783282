import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ConfigPlugin from '@/config'
import StorePlugin from '@/plugins/store.plugin'

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import './scss/app.scss'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(ConfigPlugin);
Vue.use(StorePlugin);

Vue.config.productionTip = false

Vue.prototype.playAudio = function(audioType){
  const buttonAudio = new Audio(require('../src/common/audio/button.wav'));
  const scanSuccessAudio = new Audio(require('../src/common/audio/scanSuccess.wav'));
  const scanFailureAudio = new Audio(require('../src/common/audio/scanFailure.wav'));
  switch(audioType){
    case "button":
      buttonAudio.play();
      break;
    case "scanSuccess":
      scanSuccessAudio.play();
      break;
    case "scanFailure":
      scanFailureAudio.play();
      break;
  }
}

// if using the Fully Kiosk Browser, then bind to the event hander for the
// fully.onNfcTagDiscovered function, and insert the fully object to the Vue
// object.
if (typeof FullyKiosk !== "undefined") {
  fully.showToast("Fully Kiosk Browser");
  fully.bind('onNfcTagDiscovered', 'onNfcTagDiscovered("$serial", "$type", "$message");');
  Vue.prototype.fully = fully;
}
  
// load any stored access token before the app instantiates
// before auth guards take effect.
//store.dispatch('auth/fetchAccessToken');

new Vue({
  router,
  store,
  render: h => h(App),
  mounted(){
    //Getting all mouse down events and checking if a button is in the tree to play the audio.
    document.addEventListener("mousedown", e => {
      const elementsN=e.path.length;
      for (let i=0; i<elementsN;i++){
        let path=""+e.path[i].localName;
        if(path.includes("button")){
          Vue.prototype.playAudio("button");
          break;
        }
      }
    })   
  }
}).$mount('#app')
