import { config } from "@/config";
import axios from 'axios';
import { User } from "@/models/user.model";

export const UsersService = {
  async login(username, password) {
    return await axios
      .post(`${config.apiBaseUrl}/auth/login`, {
        'username': username, 'password': password
      });
  },
  async getProfile(accessToken) {
    return await axios
      .get(`${config.apiBaseUrl}/auth/profile`, {
        headers: {'Authorization': 'bearer ' + accessToken}
      });
  },
  async all() {
    return await axios.get(`${config.apiBaseUrl}/users`);
  },
  async get(id) {
    return await axios.get(`${config.apiBaseUrl}/users/${id}`);
  },
  async add(data) {
    return await axios.post(`${config.apiBaseUrl}/users`, data);
  },
  async update(id, data) {
    return await axios.put(`${config.apiBaseUrl}/users/${id}`, data);
  },
  async delete(id) {
    return await axios.delete(`${config.apiBaseUrl}/users/${id}`);
  },
  newUser() {
    return new User();
  },
}
