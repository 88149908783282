import { AuthService } from '@/services/auth.service';
import axios from 'axios';

const NAMESPACE = 'auth/';

// mutation consts
const SET_USER = 'setUser';
const SET_ACCESSTOKEN = 'setAccessToken';
const SET_SIGNIN = 'signIn';
const SET_SIGNOUT = 'signOut';
const ERROR = 'error';
const SUCCESS = 'success';
export const AUTH_SET_USER = NAMESPACE + SET_USER;
export const AUTH_SET_ACCESSTOKEN = NAMESPACE + SET_ACCESSTOKEN;
export const AUTH_SET_SIGNIN = NAMESPACE + SET_SIGNIN;
export const AUTH_SET_SIGNOUT = NAMESPACE + SET_SIGNOUT;
export const AUTH_ERROR = NAMESPACE + ERROR;
export const AUTH_SUCCESS = NAMESPACE + SUCCESS;

// action consts
const LOGIN = 'login';
const LOGOUT = 'logout';
const LOGIN_RFID = 'loginRfid';
const FETCH_ACCESSTOKEN = 'fetchAccessToken';
export const AUTH_LOGIN = NAMESPACE + LOGIN;
export const AUTH_LOGOUT = NAMESPACE + LOGOUT;
export const AUTH_LOGIN_RFID = NAMESPACE + LOGIN_RFID;
export const AUTH_FETCH_ACCESSTOKEN = NAMESPACE + FETCH_ACCESSTOKEN;

// getter consts
const INITIALS = 'initials';
const IS_AUTHENTICATED = 'isSignedIn';
export const AUTH_INITIALS = NAMESPACE + INITIALS;
export const AUTH_IS_AUTHENTICATED = NAMESPACE + IS_AUTHENTICATED;

const ROLE_ADMIN = 'ADMIN';


const state = {
  // User login access token
  accessToken: null,
  // Current signed in User
  user: null,
  error: false,
  success: false,
}

const mutations = {
  set (state, [variable, value]) {
    state[variable] = value
  },
  [ERROR] (state, error) {
    state.error = error;
    state.success = false;
  },
  [SUCCESS] (state) {
    state.error = false;
    state.success = true;
  },
  [SET_ACCESSTOKEN] (state, token) {
    state.accessToken = token;
    // store in localStorage of browser.
    localStorage.setItem('accessToken', token);
    // set axios authorization token.
    axios.defaults.headers.common['Authorization'] = 'bearer ' + token;
  },
  [SET_USER] (state, user) {
    state.user = user;
  },
  [SET_SIGNIN] (state, { token, user }) {
    state.accessToken = token;
    state.user = user;
    localStorage.setItem('accessToken', token);
    axios.defaults.headers.common['Authorization'] = 'bearer ' + token;
  },
  [SET_SIGNOUT] (state) {
    state.accessToken = null;
    state.user = null;
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common['Authorization'];
  },
}

const getters = {
  [IS_AUTHENTICATED]: state => {
    return state.accessToken !== null;
  },
  [INITIALS]: state => {
    let initials = '';
    if (state.user) {
      const firstName = state.user.firstName? state.user.firstName : '';
      const lastName = state.user.lastName? state.user.lastName : '';
      initials = (firstName.slice(0,1) + lastName.slice(0,1)).toUpperCase();
    }
    return initials;
  }
}

const actions = {
  async [LOGIN] ({ commit }, { username, password }) {
    commit(SUCCESS);
    await AuthService.login(username, password)
      .then(async res => {
        const token = res.data.access_token;

        await AuthService.getProfile(token)
          .then(res => {
            const user = res.data;
            if (user.role.toUpperCase() === ROLE_ADMIN) {
              commit(SET_SIGNIN, { token, user });
              commit(SUCCESS);
            } else {
              commit(ERROR, 'Not Authorized');
              throw new Error('Not Authorized');
            }
          });
      })
      .catch(e => {
        console.log(e);
        commit(ERROR, e.message);
      })  
  },
  async [LOGIN_RFID] ({ commit }, { rfid }) {
    commit(SUCCESS);
    await AuthService.loginRfid(rfid)
      .then(async res => {
        const token = res.data.access_token;

        await AuthService.getProfile(token)
          .then(res => {
            const user = res.data;
            if (user.role.toUpperCase() === ROLE_ADMIN) {
              commit(SET_SIGNIN, { token, user });
              commit(SUCCESS);
            } else {
              commit(ERROR, 'Not Authorized');
              throw new Error('Not Authorized');
            }
          });
      })
      .catch(e => {
        console.log(e);
        commit(ERROR, e.message);
      })  
  },
  async [LOGOUT] ({ commit }) {
    commit(SET_SIGNOUT);
    commit(SUCCESS);
  },
  async [FETCH_ACCESSTOKEN] ({ commit }) {
    commit(SUCCESS);
    const token = localStorage.getItem('accessToken');
    commit(SET_ACCESSTOKEN, token);
    console.log(FETCH_ACCESSTOKEN);

    if (token) {
      await AuthService.getProfile(token)
      .then(res => {
        const user = res.data;
        if (user.role.toUpperCase() === ROLE_ADMIN) {
          commit(SET_SIGNIN, { token, user });
          commit(SUCCESS);
        } else {
          commit(ERROR, 'Not Authorized');
          throw new Error('Not Authorized');
        }
      })
      .catch(e => {
        commit(ERROR, e.message);
        commit(SET_SIGNOUT);
      });
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
