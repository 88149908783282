import { PlayersService } from '@/services/players.service';

const NAMESPACE = 'players/';

// mutation consts
const SET_PLAYERS = 'setPlayers';
const SET_PLAYER = 'setPlayer';
const NEW_PLAYER = 'newPlayer';
const UPDATE_FIELD = 'updateField';
const SET_STATISTICS = 'setStatistics';
const SET_LEADERBOARD = 'setLeaderboard';
const ERROR = 'error';
const SUCCESS = 'success';
export const PLAYERS_SET_PLAYERS = NAMESPACE + SET_PLAYERS;
export const PLAYERS_SET_PLAYER = NAMESPACE + SET_PLAYER;
export const PLAYERS_NEW_PLAYER = NAMESPACE + NEW_PLAYER;
export const PLAYERS_UPDATE_FIELD = NAMESPACE + UPDATE_FIELD;
export const PLAYERS_SET_STATISTICS = NAMESPACE + SET_STATISTICS;
export const PLAYERS_SET_LEADERBOARD = NAMESPACE + SET_LEADERBOARD;
export const PLAYERS_ERROR = NAMESPACE + ERROR;
export const PLAYERS_SUCCESS = NAMESPACE + SUCCESS;

// action consts
const GET_ALL = 'getAll';
const GET_ONE = 'getOne';
const GET_BY_NAME = 'getByName';
const SAVE_PLAYER = 'savePlayer';
const DELETE_PLAYER = 'deletePlayer';
const LOGIN_PLAYER = 'loginPlayer';
const REGISTER_PLAYER = 'registerPlayer';
const GET_STATISTICS = 'getStatistics';
const GET_LEADERBOARD = 'getLeaderboard';
const SAVE_KIOSK_PLAYER = 'saveKioskPlayer';
export const PLAYERS_GET_ALL = NAMESPACE + GET_ALL;
export const PLAYERS_GET_ONE = NAMESPACE + GET_ONE;
export const PLAYERS_GET_BY_NAME = NAMESPACE + GET_BY_NAME;
export const PLAYERS_SAVE_PLAYER = NAMESPACE + SAVE_PLAYER;
export const PLAYERS_DELETE_PLAYER = NAMESPACE + DELETE_PLAYER;
export const PLAYERS_LOGIN_PLAYER = NAMESPACE + LOGIN_PLAYER;
export const PLAYERS_REGISTER_PLAYER = NAMESPACE + REGISTER_PLAYER;
export const PLAYERS_GET_STATISTICS = NAMESPACE + GET_STATISTICS;
export const PLAYERS_GET_LEADERBOARD = NAMESPACE + GET_LEADERBOARD;
export const PLAYERS_SAVE_KIOSK_PLAYER = NAMESPACE + SAVE_KIOSK_PLAYER;

// getter consts
const INITIALS = 'initials';
export const PLAYERS_INITIALS = NAMESPACE + INITIALS;


const state = {
  players: [],
  player: PlayersService.newPlayer(),
  statistics: [],
  leaderboard: {name: '', value: 0, position: 0}, // TODO: newLeaderboard
  error: false,
  success: false,
}

const mutations = {
  set (state, [variable, value]) {
    state[variable] = value
  },
  [ERROR] (state, error) {
    state.error = error;
    state.success = false;
  },
  [SUCCESS] (state) {
    state.error = false;
    state.success = true;
  },
  [SET_PLAYERS] (state, players) {
    state.players = players;
  },
  [SET_PLAYER] (state, player) {
    state.player = player;
  },
  [NEW_PLAYER] (state) {
    state.player = PlayersService.newPlayer();
  },
  [UPDATE_FIELD] (state, {field, value}) {
    Object.assign(state.player, {
      [field]: value
    });
  },
  [SET_STATISTICS] (state, statistics) {
    state.statistics = statistics;
  },
  [SET_LEADERBOARD] (state, leaderboard) {
    state.leaderboard = leaderboard;
  },
}

const getters = {
  [INITIALS]: state => {
    let initials = '';
    if (state.player && state.player.displayName) {
      initials = state.player.displayName.slice(0,1).toUpperCase();
    }
    return initials;
  }
}

const actions = {
  async [GET_ALL] ({ commit }) {
    try {
      commit(SUCCESS);
      const res = await PlayersService.all();
      commit(SET_PLAYERS, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
      commit(SET_PLAYERS, []);
    }
  },
  async [GET_ONE] ({ commit }, id) {
    try {
      commit(SUCCESS);
      const res = await PlayersService.get(id);
      commit(SET_PLAYER, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
      commit(NEW_PLAYER);
    }
  },
  async [GET_BY_NAME] ({ commit }, name) {
    try {
      commit(SUCCESS);
      const res = await PlayersService.getByName(name);
      if (res.data.length > 0) {
        commit(SET_PLAYERS, res.data);
        commit(SUCCESS);
      } else {
        commit(ERROR, 'Request returned no results');
        commit(SET_PLAYERS, []);
      }
    } catch (e) {
      commit(ERROR, e.message);
      commit(SET_PLAYERS, []);
    }
  },
  async [SAVE_PLAYER] ({ state, commit }) {
    try {
      commit(SUCCESS);
      let res; 
      if (state.player._id) {
        res = await PlayersService.update(state.player._id, state.player);
      } else {
        res = await PlayersService.add(state.player);
      }
      commit(SET_PLAYER, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
  async [DELETE_PLAYER] ({ state, commit }) {
    try {
      commit(SUCCESS);
      if (state.player._id) {
        await PlayersService.delete(state.player._id);
      }
      commit(NEW_PLAYER);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
  async [LOGIN_PLAYER] ({ commit }, id) {
    try {
      commit(SUCCESS);
      const res = await PlayersService.login(id);
      if (!res.data._id) {
        console.log(res);
        throw new Error("The user doesn't exist", 'Player not found');
      }else{
        commit(SET_PLAYER, res.data);
        commit(SUCCESS);
      }
    } catch (e) {
      commit(ERROR, e.message);
      commit(NEW_PLAYER);
    }
  },
  async [REGISTER_PLAYER] ({ commit }, id) {
    try {
      commit(SUCCESS);
      const res = await PlayersService.register(id);
      if (!res) throw new Error('RegistrationFaile', 'Player not registered');
      commit(SET_PLAYER, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
      commit(NEW_PLAYER);
    }
  },
  async [GET_STATISTICS] ({ commit }, id) {
    try {
      commit(SUCCESS);
      const res = await PlayersService.getStatistics(id);
      commit(SET_STATISTICS, res.data.statistics);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
      commit(SET_STATISTICS, []);
    }
  },
  async [GET_LEADERBOARD] ({ commit }, {id, leaderboardName}) {
    try {
      commit(SUCCESS);
      const res = await PlayersService.getLeaderboard(id, leaderboardName);
      // position is zero-based, so increment by 1
      res.data.leaderboard.position += 1;
      commit(SET_LEADERBOARD, res.data.leaderboard);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
      commit(SET_LEADERBOARD, {});
    }
  },
  async [SAVE_KIOSK_PLAYER] ({ state, commit }) {
    try {
      commit(SUCCESS);
      let res; 
      if (state.player._id) {
        res = await PlayersService.kioskUpdate(state.player._id, state.player);
        commit(SET_PLAYER, res.data);
        commit(SUCCESS);
      } else {
        commit(ERROR, `Player ${state.player._id} not found`);
      }
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
