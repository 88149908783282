<template>
  <div class="home-view">
    <div class="main-panel">
      <img class="app-logo-home" alt="Nerf logo" src="@/assets/splash.png">
      <h1 class="text-center">PLAYER PORTAL</h1>
      <button type="button" class="btn btn-primary btn-large">
        <router-link to="/playerscan"><h3>Sign In</h3></router-link></button>
      <div>
        <button type="button" class="btn btn-primary btn-large" @click="goPublicLeaderboards()">
          <h3>Leaderboards</h3></button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { PLAYERS_NEW_PLAYER, PLAYERS_LOGIN_PLAYER} from '@/store/modules/players.store'
import { LEADERBOARDS_GET_ALL } from '@/store/modules/leaderboards.store';

export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      leaderboards: [],
      playerRfid: '',
      id: null,
      displayName: null,
      fullName: null,
      isNdefEnabled: false,
      abortController: null,
      showSpinner: false,
    };
  },
  mounted() {
    this.loginAnonymousPlayer();
  },
  methods: {
    goPublicLeaderboards() {
      this.$router.push({
          name: `Leaderboard`
      });
    },
     fetchLeaderboards() {
      this.$store.dispatch(LEADERBOARDS_GET_ALL)
        .then(() => {
          this.leaderboards = this.$store.state.leaderboards.leaderboards;
        });
    },
    async getLeaderboards() {
        await this.$store.dispatch(LEADERBOARDS_GET_ALL);
    },
     async loginAnonymousPlayer() {   
        await this.$store.dispatch(PLAYERS_LOGIN_PLAYER, process.env.VUE_APP_ANONYMOUS_USER)
        .then(() => {
          this.setPlayer(this.$store.state.players.player);
          this.getLeaderboards();
        })    
    },
    setPlayer(player) {
      this.player = JSON.parse(JSON.stringify(player));
      this.id = player._id;
      this.playerRfid = player.customId;
      this.displayName = player.displayName;
      this.fullName = player._id? 
        ((player.firstName? player.firstName : '-') + ' ' + 
        (player.lastName? player.lastName : '')).trim() 
        : null;
    },
  }
}
</script>

<style scoped>
button {
  margin: 5px 5px 5px 5px;
}
h1 {
  font-weight: bold;
  text-transform: uppercase;
}
.app-logo-home {
  padding: 4px;
  width: 300px;
}
.main-panel {
  width: 400px;
  height: 450px;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  margin-top: -225px;
  margin-left: -200px;
  top: 50%;
  left: 50%;
}
</style>
