import { SessionsService } from '@/services/sessions.service';

const NAMESPACE = 'sessions/';

// mutation consts
const SET_SESSIONS = 'setSessions';
const SET_SESSION = 'setSession';
const NEW_SESSION = 'newSession';
const UPDATE_FIELD = 'updateField';
const ERROR = 'error';
const SUCCESS = 'success';
export const SESSIONS_SET_SESSIONS = NAMESPACE + SET_SESSIONS;
export const SESSIONS_SET_SESSION = NAMESPACE + SET_SESSION;
export const SESSIONS_NEW_SESSION = NAMESPACE + NEW_SESSION;
export const SESSIONS_UPDATE_FIELD = NAMESPACE + UPDATE_FIELD;
export const SESSIONS_ERROR = NAMESPACE + ERROR;
export const SESSIONS_SUCCESS = NAMESPACE + SUCCESS;

// action consts
const GET_ALL = 'getAll';
const GET_ONE = 'getOne';
const GET_FOR_INSTALLATION = 'getForInstallation';
const SAVE_SESSION = 'saveSession';
const DELETE_SESSION = 'deleteSession';
const RESTART_SESSION = 'restartSession';
export const SESSIONS_GET_ALL = NAMESPACE + GET_ALL;
export const SESSIONS_GET_ONE = NAMESPACE + GET_ONE;
export const SESSIONS_GET_FOR_INSTALLATION = NAMESPACE + GET_FOR_INSTALLATION;
export const SESSIONS_SAVE_SESSION = NAMESPACE + SAVE_SESSION;
export const SESSIONS_DELETE_SESSION = NAMESPACE + DELETE_SESSION;
export const SESSIONS_RESTART_SESSION = NAMESPACE + RESTART_SESSION;


const state = {
  sessions: [],
  session: SessionsService.newSession(),
  error: false,
  success: false,

}

const mutations = {
  set (state, [variable, value]) {
    state[variable] = value
  },
  [ERROR] (state, error) {
    state.error = error;
    state.success = false;
  },
  [SUCCESS] (state) {
    state.error = false;
    state.success = true;
  },
  [SET_SESSION] (state, session) {
    state.session = session;
  },
  [SET_SESSIONS] (state, sessions) {
    state.sessions = sessions;
  },
  [NEW_SESSION] (state) {
    state.session = SessionsService.newSession();
  },
  [UPDATE_FIELD] (state, {field, value}) {
    Object.assign(state.session, {
      [field]: value
    });
  },
}

const getters = {
}

const actions = {
  async [GET_ALL] ({ commit }) {
    try {
      commit(SUCCESS);
      const res = await SessionsService.all();
      commit(SET_SESSIONS, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
      commit(SET_SESSIONS, []);
    }
  },
  async [GET_ONE] ({ commit }, id) {
    try {
      commit(SUCCESS);
      const res = await SessionsService.get(id);
      commit(SET_SESSION, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
      commit(NEW_SESSION);
    }
  },
  async [GET_FOR_INSTALLATION] ({ commit }, installationId) {
    try {
      commit(SUCCESS);
      const res = await SessionsService.getForInstallation(installationId);
      commit(SET_SESSIONS, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
      commit(SET_SESSIONS, []);
    }
  },
  async [SAVE_SESSION] ({ state, commit }) {
    try {
      commit(SUCCESS);
      let res;
      if (state.session._id) {
        res = await SessionsService.update(state.session._id, state.session);
      } else {
        res = await SessionsService.add(state.session);
      }
      commit(SET_SESSION, res.data);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
  async [DELETE_SESSION] ({ state, commit }) {
    try {
      commit(SUCCESS);
      if (state.session._id) {
        await SessionsService.delete(state.session._id);
      }
      commit(NEW_SESSION);
      commit(SUCCESS);
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
  async [RESTART_SESSION] ({ commit }) {
    try {
      commit(SUCCESS);
      if (state.session._id) {
        const res = await SessionsService.restart(state.session._id);
        commit(SET_SESSION, res.data);
        commit(SUCCESS);
      }
    } catch (e) {
      commit(ERROR, e.message);
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
