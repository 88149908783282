export class Leaderboard {
  _id = null;
  name = null;
  displayName = null;
  sortOrder = 0;
  players = [];
  createAt = null;
  updatedAt = null;
}

export class LeaderboardPlayer {
  id = null;
  displayName = null;
  statValue = 0;
  position = 0;
}
